<template>
<div class="app-container">
<el-col :sm="24" :md="14" >
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>Nuevo Administrador</span>
    </div>
    <div class="text item">
      <ValidationObserver ref="form" v-slot="{ invalid }">
          <el-form label-position="top" label-width="100px" :model="form">
              <el-image :src="$urlGlobalApi + form.image">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <el-form-item label="Foto de Perfil">
            <el-upload
                drag
                name="image"
                :http-request="postImage"
                :on-success="handleAvatarSuccess"
                multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Suelta tu foto aquí o <em>haz clic para cargar</em></div>
                <div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>
              </el-upload>
            </el-form-item>
              <el-form-item label="Nombre" size="mini">
                <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
                  <el-input v-model="form.name"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Descripción" size="mini">
                <ValidationProvider name="description" rules="required" v-slot="{ errors }">
                  <el-input v-model="form.description"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Correo electrónico" size="mini">
                <ValidationProvider name="email" rules="required" v-slot="{ errors }">
                  <el-input v-model="form.email"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <!-- <el-form-item label="Genero" size="mini">
                <ValidationProvider name="gender" rules="required" v-slot="{ errors }">
                  <el-select v-model="form.gender" placeholder="Select">
                    <el-option
                      v-for="itemGender in genderOptions"
                      :key="itemGender.value"
                      :label="itemGender.label"
                      :value="itemGender.value">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item> -->
              <el-form-item label="Rol" size="mini">
                <ValidationProvider name="rol" rules="required" v-slot="{ errors }">
                  <el-select v-model="form.rol" placeholder="Select">
                    <el-option
                      v-for="item in rolesOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Contraseña" size="mini">
                <ValidationProvider name="Password" rules="required|confirmed:passwordc|min:6" v-slot="{ errors }">
                  <el-input v-model="form.password" show-password></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Confirmar contraseña" size="mini">
                <ValidationProvider name="Password Confirmation" rules="required" vid="passwordc" v-slot="{ errors }">
                  <el-input v-model="form.password_confirmation" show-password></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item>
              <el-button
                size="mini"
                @click.stop.prevent="onUpdate"
                type="primary"
                icon="el-icon-upload2" :disabled="invalid">
                Actualizar</el-button>
              </el-form-item>
          </el-form>
        </ValidationObserver>
        </div>
    </el-card>
  </el-col>
</div>
</template>

<script>
import { getAdmin, updateAdmin } from '@/api/admin'
import { uploadImageFile } from '@/api/image'

export default {
  data () {
    return {
      form: {
        name: '',
        email: '',
        rol: '',
        description: '',
        image: '',
        gender: '',
        password: '',
        password_confirmation: ''
      },
      rolesOptions: [
        {
          label: 'Administrador',
          value: 'Administrator'
        },
        {
          label: 'Editor',
          value: 'Editor'
        }
      ],
      genderOptions: [
        {
          label: 'Femenino',
          value: 'Female'
        },
        {
          label: 'Masculino',
          value: 'Male'
        }
      ],
      loading: false
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('width', '250')
      formData.append('height', '250')
      formData.append('title', 'Luis')
      formData.append('alt', 'SafeApp-image')
      uploadImageFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response.data.path)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    async fetchData () {
      this.loading = true
      await getAdmin(this.$route.params._id)
        .then((response) => {
          this.form = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async onUpdate () {
      console.log(this.form)
      await updateAdmin(this.$route.params._id, this.form)
        .then((response) => {
          this.$message({
            showClose: true,
            message: 'Done!',
            type: 'success'
          })
          this.$router.push({ name: 'admin' })
        })
        .catch(this.responseCatch)
    },
    clearFilter () {
      this.$refs.filterTable.clearFilter()
    },
    handleAvatarSuccess (res, file) {
      this.form.image = res
      console.log(this.form.image)
    }
  }
}
</script>
